<script setup lang="ts">
    import Checkbox from 'primevue/checkbox';
    import { v4 } from 'uuid';

    defineProps<{
        errorMessage?: string;
    }>();

    const id = v4();
</script>

<template>
    <div>
        <div class="checkbox-container">
            <Checkbox :input-id="id" v-bind="$attrs" />
            <label :for="id" class="checkbox-label">
                <slot></slot>
            </label>
        </div>
        <small class="p-error">{{ errorMessage }}</small>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkbox-container {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
    }

    .checkbox-label {
        cursor: pointer;
    }
</style>
